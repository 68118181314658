<template>
  <overlay-scrollbars class="page page-softwareNet" ref="os">
    <div class="box box_offers">
      <div class="header">
        <div class="title">Software offers</div>
      </div>
      <div class="content">
        <div
          v-for="(offer, oKey) in offers" :key="oKey"
          class="offer"
        >
          <div class="offer__title">{{ offer.name }}</div>
          <div class="box box_left box_tmblers box_arrow-r">
            <div v-if="+offer.price" class="box_price">{{ +offer.price }} {{ offer.currency_code }}</div>
            <div class="box_ttl">Solution you need</div>
            <div class="box_list">
              <div
                v-for="(item, optKey) in offer.options" :key="optKey"
                class="list_item"
                :class="getOptionClass(offer.slug, item.slug)"
              >
                <Tmblr :active="item.select" :text="item.name" @changeState="optionChangeState(oKey, optKey)" />
              </div>
            </div>
            <!-- <div class="box_list">
              <div v-for="(item, pmKey) in offer.paymentMethods" :key="pmKey" class="list_item">
                <Tmblr :active="item.active" :text="item.name" @changeState="paymentMethodsChangeState(oKey, pmKey)" />
              </div>
            </div> -->
            <div v-if="offer.description && offer.description.trim()" class="arrow-r" @click="openDescription(offer)"><Arrow /></div>
          </div>
          <div class="offer__btns">
            <div class="btn btn__apply" @click="applyOffer(oKey)">{{ offer.applied ? 'Applied' : 'Apply' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box box__oDetails">
      <div class="header">
        <div class="title">Additional services</div>
      </div>
      <div class="content">
        <div class="block w100">
          <Table
            :options="addServices" :key="addServicesKey"
            @apply="applyAddService" @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import Table from '@/components/elem/Table.vue'
import Tmblr from '@/components/elem/Tmblr.vue'
import Arrow from '@/components/img/Arrow.vue'

export default {
  components: {
    Table,
    Tmblr,
    Arrow
  },
  data: () => ({
    offers: [],
    addServicesKey: 0,
    addServices: {
      colsWidth: ['5%', '16%', '16%', '16%', '12%', '16%', '10%'],
      header: [
        { ttl: '#' },
        { ttl: 'Name of IT Co.' },
        { ttl: 'Country' },
        { ttl: 'Type of Services' },
        { ttl: 'Price' },
        { ttl: 'Service time' },
        { ttl: 'Request' },
      ],
      body: [],
      paginationData: null,
    }
  }),
  created () {
    this.getOffers()
    this.getAddServices()
  },
  methods: {
    getFinName (el) {
      return !el.provider_id && !el.is_legal_company
        ? el.service_name
        : {
            type: 'info',
            html: el.service_name,
            info: {
              pos: 'tr',
              txt: 'Provided by Legal company'
            },
          }
    },
    getOffers () {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'software-offers')
        .then(({ data }) => {
          this.offers = data.data
        })
        .catch((error) => console.log(error))
    },
    getAddServices (page = 1) {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'software-add-services?page='+ page)
        .then(({ data }) => {
          this.addServices.body = []
          data.data.forEach(el => {
            this.addServices.body.push([
              el.id,
              this.getFinName(el),
              el.country ? el.country.name : '',
              el.service_type,
              el.price,
              el.service_time ? el.service_time + ' days' : '',
              { type: 'apply-btn', html: el.select ? 'Applied' : 'Apply', applyType: 'add-service', id: el.id }
            ])
          })
        })
        .catch((error) => console.log(error))
    },
    optionChangeState (offerKey, optionKey) {
      this.$set(this.offers[offerKey].options[optionKey], 'select', !this.offers[offerKey].options[optionKey].select)
    },
    // paymentMethodsChangeState (offerKey, pmKey) {
    //   this.$set(this.offers[offerKey].paymentMethods[pmKey], 'active', !this.offers[offerKey].paymentMethods[pmKey].active)
    // },
    openDescription (offer) {
      this.$store.commit('setModal', {
        template: 'software-offer-description',
        options: {
          offer: offer
        }
      })
    },
    applyOffer (offerKey) {
      // const pmSelected = []
      // this.offers[offerKey].paymentMethods.forEach(pm => {
      //   if (pm.active) pmSelected.push(pm.id)
      // })

      const sendData = {
        offer_id: +this.offers[offerKey].id,
        options: this.offers[offerKey].options,
        // payment_method_ids: pmSelected
        payment_method_ids: null
      }

      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'software-offers/toogle-apply', sendData)
        .then(({ data }) => {
          this.getOffers()
        })
        .catch((error) => console.log(error))
    },
    applyAddService (payload) {
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'software-add-services/toogle-apply', payload)
        .then(({ data }) => {
          this.getAddServices()
        })
        .catch((error) => console.log(error))
    },
    getOptionClass (offerSlug, optionSlug) {
      return offerSlug === 'mobile_app' && (optionSlug === 'ios' || optionSlug === 'android') ? 'list_item--mobile_platforms' : ''
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    background: #000000;

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .offer {
        width: 30%;
        padding-bottom: 1.5rem;

        &__title {
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
        }

        .box {
          margin: 0.25rem 0 .75rem;

          &_tmblers {
            padding: 1.5rem 2.5rem;
            background-color: #22252B;

            .row {
              display: flex;

              .box_list {
                margin-right: 2rem;
                margin-bottom: 1rem;
              }
            }

            .box_ttl {
              font-size: 14px;
              padding-bottom: .5rem;
            }

            .box_price {
              text-align: center;
              font-weight: bold;
              padding-bottom: .5rem;
            }

            .inpt {
              width: 100%;
            }

            .list_item {
              &--mobile_platforms {
                display: inline-flex;
                padding-right: 1rem;
              }
            }
          }

          &_left {
            width: 100%;

            @media (max-width: 1440px) {
              padding-left: 1.5rem;
            }
          }

          &_arrow-r {
            position: relative;
            margin-right: 3rem;

            .arrow-r {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(50%, -50%);
              width: 3rem;
              height: 3rem;
              background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              ::v-deep svg {
                width: 50%;
                height: 50%;

                path {
                  fill: #ffffff;
                }
              }
            }
          }
        }

        &__btns {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 .25rem .25rem;

          .btn {
            &__apply {
              width: 10rem;
              padding: 0.5rem 0.75rem;
              color: #62CDAC;
              text-align: center;
              background: rgba(19, 180, 151, 0.1);
              border-radius: 6px;
            }
          }
        }
      }
    }

    &__oDetails {
      .content {
        > .block {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>